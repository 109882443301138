<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item style="padding-bottom: 10px" label="设备编号">
          <a-input
            v-model:value="params.sn"
            placeholder="输入设备编号"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="车场">
          <a-select
            v-model:value="params.parkingLotId"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="请选择"
            allow-clear
            @change="getParkingPlace"
          >
            <a-select-option
              v-for="item in parkingLotList"
              :key="item.id"
              :value="item.id"
            >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="泊位编号">
          <a-input
            v-model:value="params.parkingPlaceCode"
            placeholder="输入泊位编号"
            allow-clear
          ></a-input>
        </a-form-item>
      </a-form>
      <a-form layout="inline">
        <a-form-item style="padding-bottom: 10px" label="消息类型">
          <a-select
            v-model:value="params.messageType"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="请选择"
            allow-clear
            :options="messageTypes"
          ></a-select>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="上报时间">
          <a-range-picker
            style="width: 400px"
            v-model:value="params.createTimeArr"
            :show-time="{ format: 'HH:mm:ss' }"
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始时间', '结束时间']"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="id"
          table-layout="fixed"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          :scroll="{ y: 600 }"
          bordered
        >
          <template #messageType="{ text }">
            <a-tag v-if="text == 1" color="blue">心跳消息</a-tag>
            <a-tag v-if="text == 2" color="success">车位状态</a-tag>
            <a-tag v-if="text == 3" color="red">设备配置</a-tag>
          </template>
          <template #park="{ text }">
            <a-tag v-if="text == 0" color="blue">无车</a-tag>
            <a-tag v-if="text == 1" color="success">有车</a-tag>
          </template>

        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import useDeviceYmxHistoryRecord from "@/hooks/maintenance/useDeviceYmxHistoryRecord";
import usePagination from "@/hooks/usePagination";
import { onMounted, reactive } from "vue";
import useMessage from "@/hooks/useMessage";
import { listAll } from "../../../api/parking/parkingLotApi";

export default {
  name: "index",
  components: {
    CardHeader,
  },
  setup() {
    const parkingLotList = reactive([]);
    const {
      params,
      dataList,
      total,
      loading,
      columns,
      loadData,
      messageTypes,
    } = useDeviceYmxHistoryRecord();

    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);
    const { showErr } = useMessage();

    onMounted(async () => {
      try {
        let { data } = await listAll();
        parkingLotList.push(...data);
      } catch (e) {
        showErr(e);
      }
      loadData(page.value, pageSize.value);
    });

    return {
      parkingLotList,
      params,
      page,
      pageSize,
      total,
      loading,
      currentChange,
      dataList,
      doQuery,
      doRefresh,
      columns,
      messageTypes,
    };
  },
};
</script>

<style scoped></style>
